import React from "react";
import { TabPane, CardBody, Row, Col, Input } from "reactstrap";
import { Link } from "react-router-dom";
import PaginationComponent from "../Forms/Inputs/PaginationComponent/PaginationComponent";
import { ProductsTable } from "./ProductsTable";
import { removeNullOrUndefinedProperties } from "../../helpers/api_helper";

export const ProductsTabPane = ({setData, tabId, data, apiMethods, filterOptions, user, isPublicados }) => {
  
  const handlePageChange = (newPage) => {
    let cleanFilters = removeNullOrUndefinedProperties(filterOptions);
    
    if (tabId === "1") {
      apiMethods.propiedadesPublicas.getAll({ page: newPage, ...cleanFilters })
        .then((response) => {
          setData(prevData => ({
            ...prevData,
            propiedadesPublicas: response
          }));
        });
    } else if (tabId === "2") {
      apiMethods.inmuebles.getAll({ page: newPage, status_inmueble: 1, ...cleanFilters })
        .then((response) => {
          setData(prevData => ({
            ...prevData,
            propiedadesPropias: response
          }));
        });
    } else if (tabId === "3") {
      apiMethods.inmuebles.getAll({ page: newPage, status_inmueble: 5, ...cleanFilters })
        .then((response) => {
          setData(prevData => ({
            ...prevData,
            propiedadesInactivas: response
          }));
        });
    }
  };

  return (
    <TabPane tabId={tabId}>
      <CardBody className="border border-dashed border-end-0 border-start-0">
        <form>
          <Row className="g-3">
            <Col>
              <div className="search-box me-2 mb-2 d-inline-block col-12">
                <Input id={`search-bar-${tabId}`} type="text" className="form-control search" />
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </Col>
            {isPublicados && (
              <div className="col-sm-auto ms-auto">
                <Link className="btn btn-primary" to="/formulario-inmueble">
                  <i className="ri-add-line align-bottom me-1"></i> Agregar Inmueble
                </Link>
              </div>
            )}
          </Row>
        </form>
      </CardBody>
      <div className="card-body pt-0">
        {data.results.length > 0 ? (
          <>
            <ProductsTable
              data={data.results}
              apiMethods={apiMethods}
              filterOptions={filterOptions}
              user={user}
            />
            <PaginationComponent
              activePage={data.page_number}
              totalPages={data.num_pages}
              onPageChange={handlePageChange}
            />
          </>
        ) : (
          <div className="py-4 text-center">
            <div>
              <lord-icon
                src="https://cdn.lordicon.com/msoeawqm.json"
                trigger="loop"
                colors="primary:#25a0e2,secondary:#00bd9d"
                style={{ width: "72px", height: "72px" }}
              ></lord-icon>
            </div>
            <div className="mt-4">
              <h5>Disculpa! No se encontraron resultados</h5>
            </div>
          </div>
        )}
      </div>
    </TabPane>
  );
};
