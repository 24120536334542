import React, { useContext, useMemo, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Badge, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import TableContainer from "../../Components/Common/TableContainer";
import { Published, Price } from "../Ecommerce/EcommerceProducts/EcommerceProductCol";
import { PROPIEDAD_ACL } from "../../helpers/entities";
import Can, { AbilityContext } from "../../helpers/acl/Can";
import { ReservaModal } from "../Ecommerce/EcommerceProducts/ReservaModal";

export const ProductsTable = ({ data, apiMethods, filterOptions, user }) => {
  const history = useNavigate();
  const ability = useContext(AbilityContext)


  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: (cell) => (
          <input
            type="checkbox"
            className="productCheckBox form-check-input"
            value={cell.row.original.id}
            onChange={() => {}} // Implement checkbox logic
          />
        ),
      },
      {
        Header: "Inmueble",
        Cell: (product) => (
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0 me-3">
              <div className="avatar-container-sm bg-light rounded p-1">
                <img src={product.row.original.first_image_url} alt="" className="img-fluid d-block" />
              </div>
            </div>
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                <Link to={`/inmueble/${product.row.original.id}`} className="text-dark">
                  {product.row.original.nombre}
                </Link>
              </h5>
              <p className="text-muted mb-0">
                <span className="fw-medium">{product.row.original?.tipo_inmueble?.nombre}</span>
              </p>
            </div>
          </div>
        ),
      },
      {
        Header: "Franquicia",
        accessor: "franquicia",
        Cell: (cellProps) => (
          <div className="d-flex align-items-center">
            <p className="text-muted mb-0">
              <span className="fw-medium">{cellProps.row.original?.franquicia?.nombre}</span>
            </p>
          </div>
        ),
      },
      {
        Header: "Precio",
        accessor: "precio",
        Cell: (cellProps) => <Price {...cellProps} />,
      },
      {
        Header: "Estatus",
        accessor: "status",
        Cell: (cellProps) => (
          <Badge color="primary">{cellProps.row.original?.status_inmueble?.nombre}</Badge>
        ),
      },
      {
        Header: "Fecha publicacion",
        accessor: "fecha_publicacion",
        Cell: (cellProps) => <Published {...cellProps} />,
      },
      {
        Header: " ",
        Cell: (cellProps) => {
          const propiedadRow = { ...cellProps.row.original, obj_type: PROPIEDAD_ACL };
          const modalReservaRef = useRef(null);

          return (
            <UncontrolledDropdown>
              <DropdownToggle className="btn btn-soft-secondary btn-sm" tag="button">
                <i className="ri-more-fill" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  onClick={() => {
                    const productData = cellProps.row.original;
                    history(`/inmueble/${productData.id}`);
                  }}>
                  <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{" "}
                  Ver Detalles
                </DropdownItem>
                    <Can I="update" this={propiedadRow} ability={ability}>
                      <DropdownItem
                        onClick={() => {
                          const productData = cellProps.row.original;
                          history(`/formulario-inmueble/${productData.id}`);
                        }}>
                        <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                        Editar
                      </DropdownItem>
                    </Can>  
                      {/* <ReservaModal
                        inmueble={propiedadRow}
                        ref={modalReservaRef}>
                      </ReservaModal> */}
                    {/* <Can I="update" this={propiedadRow} ability={ability}>
                      <DropdownItem
                        onClick={() => {
                          modalReservaRef.current.openForm()
                        }}>
                        <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                        Reservar
                      </DropdownItem>
                    </Can>   */}
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    [user.ability]
  );

  return (
    <TableContainer
      columns={columns}
      data={data}
      customPageSize={1000}
      divClass="table-responsive mb-1"
      tableClass="mb-0 align-middle table-borderless"
      theadClass="table-light text-muted"
    />
  );
};