import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import './TablaCrud.css';
import { ToastContainer, toast } from 'react-toastify';
import { Button, ButtonGroup, Card, Col, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import DeleteModal from '../Common/DeleteModal';
import TableContainer from '../Common/TableContainer';
import PaginationComponent from '../../pages/Forms/Inputs/PaginationComponent/PaginationComponent';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

const paginationModel = {
    count: null,
    page_number: 1,
    num_pages: 1,
    per_page: 10,
    next: null,
    previous: null,
}

const TablaCrud = forwardRef(({
    apiLink, 
    columns,
    setResourceItem,
    formularioModal, 
    validation,
    cardDisplay,
    onAddMulti,
    onRemoveMulti,
    handleSelection,
    ACLPrefix,
    ...props},ref) => {
    const [activeFilters, setActiveFilters] = useState({});
    const [selectedItems, setSelectedItems] = useState([]);

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const [isEdit, setIsEdit] = useState(false);
    const [resource, setResource] = useState();
    const [deleteItem, setdeleteItem] = useState(null);
    const [tableStyle, setTableStyle] = useState(true);
    
    const defaultdate = () => {
        let d = new Date(),
          months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
        return ((d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear()).toString());
    };

    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteModalMulti, setDeleteModalMulti] = useState(false);
    const [modal, setModal] = useState(false);
    const [date, setDate] = useState(defaultdate());
    const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
    const [isLoading, setisLoading] = useState();

    const toggleLoading = useCallback(() => {
        setisLoading(!isLoading)
    }, [isLoading, setisLoading]);

    const deleteCheckbox = () => {
        const ele = document.querySelectorAll(".taskCheckBox:checked");
        ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
        setSelectedCheckBoxDelete(ele);
    };

    const checkedAll = useCallback(() => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".taskCheckBox");

        if (checkall.checked && resource && resource?.results) {
            setSelectedItems(resource.results);
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            setSelectedItems([]);
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
        deleteCheckbox();
    }, [resource]);

    const handleItemSelect = (item) => {
        setSelectedItems((prev) => {
            return handleSelection(prev, item);
        });
    };

    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
            setResourceItem(null)
        } else {
            setModal(true);
            setDate(defaultdate());
        }
    }, [modal,setResourceItem]);

    const reloadResources = useCallback((filt = activeFilters) => {
        apiLink.getAll(filt).then((res)=>{
            let ACL = ACLPrefix
            if(ACLPrefix){
                let items = res.results
                res.results = items.map((e)=> {
                    e.obj_type = ACL
                    return e
                })
            }
            console.log(res)
            setResource(res)
        })
    }, [apiLink, setResource]);

    const paginationCallback = (page, page_size = resource.per_page) => {
        apiLink.getAll({page_size, page, ...activeFilters}).then((res)=>{
            setResource(res)
        })
    }

    useEffect(() => {
        if (!resource) {
            reloadResources()
        }
    }, [reloadResources,resource, activeFilters]);  

    useEffect(() => {
        reloadResources(activeFilters)
    }, [activeFilters]);  

    const  editItem = (arg) => {
        setResourceItem(arg)
        setIsEdit(true);
        toggle();
    }

    const handleClickEdit = useCallback(editItem, [toggle,setResourceItem]);

    useImperativeHandle(ref, () => ({
        toggle,
        toggleLoading,
        reloadResources,
        handleClickEdit,
        setTableStyle,
        activeFilters, 
        setActiveFilters,
        selectedItems,
        setSelectedItems
    }));

    const handleSave = () => () =>{
        validation.submitForm()
    }

    useEffect(()=>{
        console.log(selectedItems)
    },[selectedItems])

    const columnas = useMemo(() => {
        const baseColumns = [
            {
                Header: <input 
                    type="checkbox" 
                    id="checkBoxAll" 
                    className="form-check-input" 
                    onChange={checkedAll}
                    checked={selectedItems.length === resource?.results?.length}
                />,
                Cell: ({ row }) => (
                    <>
                        <input 
                            type="checkbox" 
                            className="taskCheckBox form-check-input" 
                            value={row.original.id} 
                            onChange={() => handleItemSelect(row.original)}
                            checked={selectedItems.some(item => item.id == row.original.id)}
                        />
                    </>
                ),
                id: '#',
            },
            ...columns,
        ];
        console.log('seteo columnas',selectedItems)
        if (!props.read_only && props.edit) {
            baseColumns.push({
                Header: "Opciones",
                Cell: ({ row }) => (
                    <ul className="list-inline hstack gap-2 mb-0">
                        <li className="list-inline-item">
                            <button 
                                type="button" 
                                onClick={() => editItem(row.original)}
                                className="btn btn-sm btn-primary waves-effect waves-light">
                                <i className="ri-pencil-fill"></i>
                            </button>
                        </li>
                        <li className="list-inline-item">
                            <button 
                                type="button" 
                                onClick={() => onClickDelete(row.original)}
                                className="btn btn-sm btn-primary waves-effect waves-light">
                                <i className="ri-delete-bin-fill"></i>
                            </button>
                        </li>
                    </ul>
                ),
            });
        }

        return baseColumns;
    }, [columns, checkedAll, handleItemSelect, selectedItems, resource, props.read_only, props.edit, editItem]);

    const optionsColumns = {
        Header: "Opciones",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
                <li className="list-inline-item">
                    <button 
                        type="button" 
                        onClick={() => { 
                            const taskData = cellProps.row.original; 
                            handleClickEdit(taskData); 
                        }}
                        className="btn btn-sm btn-primary waves-effect waves-light">
                            <i className="ri-pencil-fill"></i>
                    </button>
                </li>
                <li className="list-inline-item">
                    <button 
                        type="button" 
                        onClick={() => { 
                            const taskData = cellProps.row.original; 
                            onClickDelete(taskData); 
                        }}
                        className="btn btn-sm btn-primary waves-effect waves-light">
                            <i className="ri-delete-bin-fill"></i>
                    </button>
                </li>
            </ul>
          );
        },
    }

    if(!props.read_only && props.edit){
        columnas.push(optionsColumns)
    }
    const columns_ = useMemo(
        () => {
            return columnas
        },
        [handleClickEdit, checkedAll, columns,columnas,selectedItems]
    );

    const onClickDelete = (res) => {
        setdeleteItem(res)
        setDeleteModal(true);
    };

    const confirmDeleteResource = () => {
        if (deleteItem) {
            setisLoading(true)
            apiLink.delete(deleteItem).then((res)=>{
                toast.success(`${props.nombreSingular} eliminado con éxito.`)
                reloadResources()
            }).catch(toast.error).finally(()=>{
                setDeleteModal(false);
                setisLoading(false)
                setdeleteItem(null)
            })
        }
    };

    const deleteMultiple = () => {
        setisLoading(true);
        const promises = selectedItems.map(item => apiLink.delete(item));
      
        Promise.all(promises)
          .then(() => {
            toast.success(`${props.nombrePlural} eliminados con éxito.`);
            reloadResources();
            setSelectedItems([]);
          })
          .catch(toast.error)
          .finally(() => {
            setIsMultiDeleteButton(false);
            setisLoading(false);
          });
    };

    const renderPaginationItems = () => {
        return (
          <PaginationComponent
            activePage={resource.page_number}
            totalPages={resource.num_pages}
            onPageChange={paginationCallback}/>
        );
    };

    return (
        <>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={confirmDeleteResource}
                onCloseClick={() => setDeleteModal(false)}/>

            <DeleteModal
                show={deleteModalMulti}
                onDeleteClick={() => {
                    deleteMultiple();
                    setDeleteModalMulti(false);
                }}
                onCloseClick={() => setDeleteModalMulti(false)}/>
            <div className="row">
                <Col lg={12}>
                    <div className="card" id="tasksList">
                        <div className="card-header border-0">
                            <div className="d-flex align-items-center">
                            <h5 className="card-title mb-0 flex-grow-1">Lista de {`${props.nombrePlural}`}</h5>
                            {
                                (!props.read_only && props.add) &&
                                <div className="flex-shrink-0">
                                    <div className="d-flex flex-wrap gap-2">
                                    <button 
                                        className="btn btn-danger add-btn me-1" 
                                        onClick={() => { 
                                            setIsEdit(false); 
                                            toggle(); 
                                        }}><i className="ri-add-line align-bottom me-1"></i> Agregar {`${props.nombreSingular}`} 
                                    </button>
                                    {selectedItems.length > 0 && 
                                        <button 
                                            className="btn btn-soft-danger" 
                                            onClick={() => setDeleteModalMulti(true)}>
                                            <i className="ri-delete-bin-2-line"></i>
                                        </button>
                                    }

                                    </div>
                                </div>
                            }
                            {
                                (cardDisplay && !isMobile) &&
                                <ButtonGroup className="mt-4 mt-sm-0">
                                    <Button onClick={() => setTableStyle(false)} color="light" className="btn-icon"> <i className="ri-layout-grid-line" /> </Button>
                                    <Button onClick={() => setTableStyle(true)} color="light" className="btn-icon"> <i className="ri-table-line" /> </Button>
                                </ButtonGroup>
                            }                            
                                <ButtonGroup className="ml-2 mt-4 mt-sm-0">
                                    {props.children}
                                </ButtonGroup>
                            </div>
                        </div>
                        <div className="card-body pt-0">
                            {
                                (resource && resource.results) || isLoading ? (
                                    <>
                                        {
                                            ((!isMobile && tableStyle)) ?
                                            <TableContainer
                                                columns={columns_}
                                                data={(resource.results || [])}
                                                isGlobalFilter={true}
                                                isAddUserList={false}
                                                customPageSize={1000}
                                                className="custom-header-css"
                                                divClass="table-responsive heigh400 table-card mb-3"
                                                tableClass="align-middle table-nowrap mb-0"
                                                theadClass="table-light table-nowrap"
                                                thClass="table-light text-muted"
                                            /> :
                                            cardDisplay &&
                                            <Row className="row-cols-xxl-5 row-cols-lg-3 row-cols-1">
                                                {resource.results.map((item,index) => (
                                                    <Col key={index}>
                                                        {cardDisplay(item)}
                                                    </Col>
                                                ))}
                                            </Row>                                        
                                        }
                                        {
                                            renderPaginationItems()
                                        }
                                    </>
                                ) : (
                                    <div className='w-100 text-center mb-5 mt-5'>
                                        <div className="spinner-border text-primary" role="status">
                                        </div>
                                    </div>
                                )
                            }
                            <ToastContainer closeButton={false} limit={1} />
                        </div>
                    </div>
                </Col>
            </div>


            {
                (formularioModal) &&
                <Modal
                    isOpen={modal}
                    toggle={toggle}
                    centered
                    size="lg"
                    className="border-0"
                    modalClassName='modal fade zoomIn'>
                    <ModalHeader className="p-3 bg-soft-info" toggle={toggle}>
                        {!!isEdit ? `Editar ${props.nombreSingular}` : `Crear ${props.nombreSingular}`}
                    </ModalHeader>
                    <ModalBody className="modal-body">
                        {formularioModal}
                    </ModalBody>
                    {
                        !props.modalMute &&
                        <div className="modal-footer">
                            <div className="hstack gap-2 justify-content-end">
                                <Button
                                    disabled={isLoading}
                                    type="button"
                                    onClick={() => {
                                        setModal(false);
                                    }}
                                    className="btn-light">
                                        Cerrar
                                </Button>
                                <Button
                                    disabled={isLoading}
                                    onClick={handleSave()} 
                                    type="button" 
                                    className="btn btn-success">
                                        {!!isEdit ? `Editar ${props.nombreSingular}` : `Crear ${props.nombreSingular}`}
                                </Button>
                            </div>
                        </div>
                    }
                </Modal>            
            }
        </>
    );
});

TablaCrud.displayName = 'TablaCrud';
export { TablaCrud }