import React, { useEffect, useMemo, useState } from "react";
import './FranquiciasList.css';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown
} from "reactstrap";

import DeleteModal from "../../../Components/Common/DeleteModal";

import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";
//Import data
import { InputSelect } from "../../Forms/Inputs/InputSelect/InputSelect";
//Import actions
import { useFormik } from "formik";
import { ToastContainer, toast } from 'react-toastify';
//redux
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../AuthContext";
import { deleteFranquicia } from "../../../helpers/api_calls";
import { getciudades, getestados, getfranquiciasList, getpropiedades } from "../../../store/actions";
import { InputRangeSlider } from "../../Forms/Inputs/InputRangeSlider/InputRangeSlider";
import { AddFranquicias } from "../components/AddFranquicias/AddFranquicias";

export const FranquiciasList = () => {
  const { user } = useAuth()
  const history = useNavigate();
    const dispatch = useDispatch();
    const [modalState, setModalState] = useState(false);
    const [franquicias, setFranquicias] = useState({
      count      : 0,
      page_number: 1,
      num_pages  : 1,
      per_page   : 10,
      next       : null,
      previous   : null,
      results    : []
    });
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteModalMulti, setDeleteModalMulti] = useState(false);
    const [product, setProduct] = useState(null);
    const [editFranquicia, seteditFranquicia] = useState(null);

    const toggleModal = () => () => {
        setModalState(!modalState);
        seteditFranquicia(null);
    };

    const toggleModalChild = () => {
        setModalState(!modalState);
        seteditFranquicia(null);
    };

    const editarFranquicia = (franquicia) => () => {
      seteditFranquicia(franquicia);
      setModalState(true);
    };
    const { franquiciasStore } = useSelector((state) => ({
        franquiciasStore: state.Franquicias,
    }));

    const { propiedades } = useSelector((state) => ({
      propiedades: state.Propiedades,
    }));
  
    useEffect(() => {
        dispatch(getfranquiciasList({page_size:30}));
        dispatch(getciudades({page_size: 1000}));
        dispatch(getestados({page_size: 1000}));
    }, [dispatch]);

    useEffect(() => {
      if(franquiciasStore.franquicias){
        setFranquicias(franquiciasStore.franquicias)
      }
    }, [franquiciasStore]);

    const pageUpdate = (options)=>{
      dispatch(getpropiedades(options));
    }

    // Displat Delete Button
    const [dele, setDele] = useState(0);
    const displayDelete = () => {
      const ele = document.querySelectorAll(".productCheckBox:checked");
      const del = document.getElementById("selection-element");
      setDele(ele.length);
      if (ele.length === 0) {
        del.style.display = 'none';
      } else {
        del.style.display = 'block';
      }
    };
  
    // Delete Multiple
    const deleteMultiple = () => {
      const promises = []
      const ele = document.querySelectorAll(".productCheckBox:checked");
      const del = document.getElementById("selection-element");
      ele.forEach((element) => {
        let deleteCall = deleteFranquicia(element.value)
        promises.push(deleteCall)
        del.style.display = 'none';
      });

      Promise.all(promises).then((res)=>{
        toast.success("Franquicias eliminadas", { autoClose: 3000 });
        dispatch(getfranquiciasList({page_size:30}));
      }).catch((err)=>{
        toast.error("Ha ourrido un error al eliminar franquicia", { autoClose: 3000 });
      })
    };
  
    const columns = useMemo(
      () => [
        {
          Header: "#",
          Cell: (cell) => {
            return <input type="checkbox" className="productCheckBox form-check-input" value={cell.row.original.id} onClick={() => displayDelete()} />;
          },
        },
        {
          Header: "Inmueble",
          Cell: (franquicia) => (
            <>
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 me-3">
                  <div className="avatar-sm bg-light rounded p-1">
                    <img
                          src={franquicia.row.original.first_image_url}
                          alt=""
                          className="img-fluid d-block"/>
                  </div>
                </div>
                <div className="flex-grow-1">
                  <h5 className="fs-14 mb-1">
                    <Link
                      to={`/franquicia/${franquicia.row.original.id}`}
                      className="text-dark"
                    >
                      {" "}
                      {franquicia.row.original.nombre}
                    </Link>
                  </h5>
                  <p className="text-muted mb-0">
                    {/* Category :{" "} */}
                    <span className="fw-medium">
                      {" "}
                      {franquicia.row.original?.tipo_franquicia?.nombre || ' '}
                    </span>
                  </p>
                </div>
              </div>
            </>
          ),
        },
        {
          Header: "Franquicias Comerciales",
          accessor: "franquicias_comerciales",
          filterable: false,
          Cell: (franquicia) => {
            return franquicia.row.original.franquicias_comerciales.length
          },
        },

        {
          Header: "Franquicias Personales",
          accessor: "franquicias_personales",
          filterable: false,
          Cell: (franquicia) => {
            const franquicia_item = franquicia.row.original
            return franquicia_item.franquicias_personales ? franquicia_item.franquicias_personales.length : 0
          },
        },
        {
          Header: "Membresias",
          accessor: "membresias",
          filterable: false,
          Cell: (franquicia) => {
            const franquicia_item = franquicia.row.original
            return franquicia_item.membresias ? franquicia_item.membresias.length : 0
          },
        },
        {
          Header: "Fecha publicacion",
          accessor: "fecha_publicacion",
          filterable: false,
          Cell: (cellProps) => {
            // return (cellProps.franquicias_comerciales.length || );
            return 0
          },
        },
        {
          Header: " ",
          Cell: (cellProps) => {
            return (
              <UncontrolledDropdown>
                <DropdownToggle
                  href="#"
                  className="btn btn-soft-secondary btn-sm"
                  tag="button">
                  <i className="ri-more-fill" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">0
                  <DropdownItem
                    onClick={() => {
                      const franquicia = cellProps.row.original;
                      history(`/franquicia/${franquicia.id}`);
                    }}>
                    <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{" "}
                    Ver Detalles
                  </DropdownItem>
  
                  <DropdownItem
                    onClick={editarFranquicia(cellProps.row.original)}>
                    <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                    Editar
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            );
          },
        },
      ],[history]
    );
  
    document.title = "Franquicias | Inmoapp";
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            estado       : null,
            ciudad       : null,
            banios       : null,
            franquicia   : null,
            min_precio   : 0,
            max_precio   : 0,
            tipo_inmueble: null,
        },
    });
  
    return (
      <div className="page-content">
        <AddFranquicias 
          modalState={modalState} 
          toggleModal={toggleModalChild}
          editObject={editFranquicia}>
        </AddFranquicias>
        <ToastContainer closeButton={false} limit={1} />
        {/* <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteProduct}
          onCloseClick={() => setDeleteModal(false)}
        /> */}
        <DeleteModal
          show={deleteModalMulti}
          onDeleteClick={() => {
            deleteMultiple();
            setDeleteModalMulti(false);
          }}
          onCloseClick={() => setDeleteModalMulti(false)}
        />
        <Container fluid>
          <BreadCrumb title="Franquicias" pageTitle="Franquicias" />
  
          <Row>
            {/* <Col xl={3} lg={4}>
              <Card>
                <CardHeader >
                  <div className="d-flex mb-3">
                    <div className="flex-grow-1">
                      <h5 className="fs-16">Filtros</h5>
                    </div>
                    <div className="flex-shrink-0">
                      <Link to="#" className="text-decoration-underline link-secondary">
                        Limpiar Todo
                      </Link>
                    </div>
                  </div>
  
                  <div className="filter-choices-input">
                    <InputSelect
                        name         = "estado"
                        label        = "Estado"
                        validation   = {validation}
                        optionsProps = {
                            {
                                value:'id',
                                label:'nombre',
                                items:propiedades.estados.results || []
                            }}>
                    </InputSelect>
                  </div>
                  <div className="filter-choices-input">
                    <InputSelect
                        name         = "ciudad"
                        label        = "Ciudades"
                        validation   = {validation}
                        optionsProps = {
                            {
                                value:'id',
                                label:'nombre',
                                items:propiedades.ciudades.results || []
                            }}>
                    </InputSelect>
                  </div>
                </CardHeader>
  
                <div className="accordion accordion-flush">
                  <div className="card-body border-bottom">
                    <InputRangeSlider
                      minValue={0}
                      maxValue={99999999}
                      minName="min_precio"
                      maxName="max_precio"
                      validation={validation}
                      label="Precio">
                    </InputRangeSlider>
                  </div>
  
                  <div className="card-body border-bottom">
                    <InputRangeSlider
                      minValue={0}
                      maxValue={50}
                      minName="min_habitaciones"
                      maxName="max_habitaciones"
                      validation={validation}
                      label="Habitaciones">
                    </InputRangeSlider>
                  </div>
  
                </div>
              </Card>
            </Col>
   */}
            <div className="col-xl-9 col-lg-8">
              <div>
                <div className="card">
                  <div className="card-header border-0">
                    <div className="row align-items-center">
                      <div className="col-auto">
                        <div id="selection-element">
                          <div className="my-n1 d-flex align-items-center text-muted">
                            Seleccionar{" "}
                            <div
                              id="select-content"
                              className="text-body fw-semibold px-1"
                            >{dele}</div>{" "}
                            Resultado{" "}
                            <button
                              type="button"
                              className="btn btn-link link-danger p-0 ms-3"
                              onClick={() => setDeleteModalMulti(true)}>
                              Eliminar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <CardBody className="border border-dashed border-end-0 border-start-0">
                      <form>
                        <Row className="g-3">
                          <Col>
                            {/* <div className={0 > 1 ? "search-box me-2 mb-2 d-inline-block" : "search-box me-2 mb-2 d-inline-block col-12"}>
                              <input
                                id="search-bar-0"
                                type="text"
                                className="form-control search /"/>
                              <i className="bx bx-search-alt search-icon"></i>
                            </div> */}
  
                            <div className="search-box me-2 mb-2 d-inline-block col-12">
                              <input
                                id="search-bar-0"
                                type="text"
                                className="form-control search /"/>
                              <i className="bx bx-search-alt search-icon"></i>
                            </div>
                          </Col>
                          <div className="col-sm-auto ms-auto">
                            <div>
                                <Button className="btn btn-primary" onClick={toggleModal()} >
                                  <i className="ri-add-line align-bottom me-1"></i> Agregar
                                    Franquicia
                                </Button>

                            </div>
                        </div>
                        </Row>
                      </form>
                    </CardBody>
                  <div className="card-body pt-0">
                    {franquicias.results.length > 0 ? (
                      <TableContainer
                        paginatedResponse={franquicias}
                        apicallback={pageUpdate}
                        columns={columns}
                        filtersetValues={validation.values}
                        data={(franquicias.results || [])}
                        customPageSize={10}
                        divClass="table-responsive mb-1"
                        tableClass="mb-0 align-middle table-borderless"
                        theadClass="table-light text-muted"
                      />
                    ) : (
                      <div className="py-4 text-center">
                        <div>
                          <lord-icon
                            src="https://cdn.lordicon.com/msoeawqm.json"
                            trigger="loop"
                            colors="primary:#25a0e2,secondary:#00bd9d"
                            style={{ width: "72px", height: "72px" }}
                          ></lord-icon>
                        </div>
  
                        <div className="mt-4">
                          <h5>Disculpa! No se encontraron resultados</h5>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    );
};
