import { useContext, useEffect, useMemo, useState } from "react";
import { createApiMethods } from "../../helpers/api_calls";
import * as url from "../../helpers/api_urls";
import { useAuth } from "../../AuthContext";
import { AbilityContext } from "../../helpers/acl/Can";
import { useNavigate } from "react-router-dom";
import { PROPIEDAD_ACL } from "../../helpers/entities";
import { useFormik } from "formik";
import * as Yup from "yup";

export const usePropertyForm = (propertyData, isEdit, handleSaveProperty) => {
  const { user } = useAuth();
  const asesor = user.isAdmin() ? null : user.id;
  const franquicia__ = user.isAdmin() ? null : user.franquicia()?.id;

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      nombre                       : propertyData ? propertyData.nombre                       : undefined,
      descripcion                  : propertyData ? propertyData.descripcion                  : undefined,
      numero_banios                : propertyData ? propertyData.numero_banios                : 0,
      habitaciones                 : propertyData ? propertyData.habitaciones                 : 0,
      numero_plantas               : propertyData ? propertyData.numero_plantas               : 0,
      metros_cuadrados_terreno     : propertyData ? propertyData.metros_cuadrados_terreno     : 0,
      metros_cuadrados_construccion: propertyData ? propertyData.metros_cuadrados_construccion: 0,
      metraje_total                : propertyData ? propertyData.metraje_total                : 0,
      fecha_publicacion            : propertyData ? propertyData.fecha_publicacion            : undefined,
      ptos_estacionamiento         : propertyData ? propertyData.ptos_estacionamiento         : 0,
      direccion                    : propertyData ? propertyData.direccion                    : undefined,
      precio                       : propertyData ? propertyData.precio                       : undefined,
      precio_alquiler                       : propertyData ? propertyData.precio_alquiler                       : undefined,
      franquicia                   : propertyData ? propertyData.franquicia                   : franquicia__,
      tipo_inmueble                : propertyData ? propertyData.tipo_inmueble                : undefined,
      ciudad                       : propertyData ? propertyData.ciudad                       : undefined,
      estado                       : propertyData ? propertyData.estado                       : undefined,
      tipo_negociacion             : propertyData ? propertyData.tipo_negociacion             : undefined,
      status_inmueble              : propertyData ? propertyData.status_inmueble              : undefined,
      precio_inicial               : propertyData ? propertyData.precio_inicial               : undefined,
      antiguedad                   : propertyData ? propertyData.antiguedad                   : undefined,
      vista                        : propertyData ? propertyData.vista                        : undefined,
      hab_servicio                 : propertyData ? propertyData.hab_servicio                 : 0,
      banio_servicio               : propertyData ? propertyData.banio_servicio               : 0,
      lavadero                     : propertyData ? propertyData.lavadero                     : false,
      terraza_jardin               : propertyData ? propertyData.terraza_jardin               : false,
      meletero_bodega              : propertyData ? propertyData.meletero_bodega              : false,
      tanque_agua                  : propertyData ? propertyData.tanque_agua                  : false,
      amoblado                     : propertyData ? propertyData.amoblado                     : undefined,
      planta_electrica             : propertyData ? propertyData.planta_electrica             : false,
      posee_hipoteca               : propertyData ? propertyData.posee_hipoteca               : false,
      estudio                      : propertyData ? propertyData.estudio                      : false,
      family_room                  : propertyData ? propertyData.family_room                  : false,
      tanque_agua_comun            : propertyData ? propertyData.tanque_agua_comun            : false,
      ascensor                     : propertyData ? propertyData.ascensor                     : false,
      seguridad                    : propertyData ? propertyData.seguridad                    : false,
      parque_infantil              : propertyData ? propertyData.parque_infantil              : false,
      piscina                      : propertyData ? propertyData.piscina                      : false,
      gym                          : propertyData ? propertyData.gym                          : false,
      bbq                          : propertyData ? propertyData.bbq                          : false,
      salon_fiestas                : propertyData ? propertyData.salon_fiestas                : false,
      cancha_deportiva             : propertyData ? propertyData.cancha_deportiva             : false,
      pozo_profundo                : propertyData ? propertyData.pozo_profundo                : false,
      planta_electrica_comun       : propertyData ? propertyData.planta_electrica_comun       : false,
      asesor                       : propertyData ? propertyData.asesor                       : asesor,
      cliente                      : propertyData ? propertyData.cliente                      : undefined,
      cliente_nombre               : undefined,
      cliente_tipo_contacto        : undefined,
      cliente_contacto_valor       : undefined,
      createNewCliente             : false,
    },
    validationSchema: Yup.object({
      ciudad: Yup.number().required("Seleccionar una Ciudad es obligatorio"),
      estado: Yup.number().required("Seleccionar un estado es obligatorio."),
      franquicia: Yup.number().required("Seleccionar una franquicia es obligatorio."),
      status_inmueble: Yup.number().required("Seleccionar un status es obligatorio."),
    }),
    onSubmit: handleSaveProperty,
  });

  return { validation };
};

export const usePropertyData = (idinmueble, dropZoneRef, history) => {
  const APIpropiedadesPrivadas = useMemo(() => createApiMethods(url.PROPIEDADES), []);
  const [propertyData, setPropertyData] = useState(null);
  const [isLoadingPropiedad, setisLoadingPropiedad] = useState(false);
  const [isEdit, setisEdit] = useState(false);
  const ability = useContext(AbilityContext);

  const convertirObjeto = (obj) => {
    const objetoPlano = {};
    for (const key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        const subObj = obj[key];
        objetoPlano[key] = subObj.id;
      } else {
        objetoPlano[key] = obj[key];
      }
    }
    return objetoPlano;
  };

  useEffect(() => {
    if (idinmueble) {
      setisLoadingPropiedad(true);
      APIpropiedadesPrivadas.getDetails(idinmueble).then((res) => {
        const objetoplano = convertirObjeto(res);
        const property = {
          ...objetoplano,
          obj_type: PROPIEDAD_ACL,
          franquicia_: res.franquicia,
        };

        const hability_authorization = ability.can("update", property);
        if (hability_authorization) {
          setisEdit(true);
        } else {
          history(`/inmueble/${idinmueble}`);
        }

        // Access the GaleriaDropzone ref and set preloaded images
        if (dropZoneRef.current && res.imagenes) {
          dropZoneRef.current.setuploadedFiles(res.imagenes);
        }

        setPropertyData(property);
        setisEdit(true);
        setisLoadingPropiedad(false);
      });
    } else {
      setisEdit(false);
    }
  }, [idinmueble, APIpropiedadesPrivadas, dropZoneRef, history, ability]);

  const handleSaveProperty = async (values, actions) => {
    try {
      const formData = new FormData();

      for (const [key, value] of Object.entries(values)) {
        if (value !== null && value !== undefined && value !== "") {
          if (key !== "imagenes") {
            formData.append(key, value);
          } else {
            value.forEach((image, index) => {
              formData.append(`imagenes[${index}]`, image);
            });
          }
        }
      }

      let propUpdated;
      if (isEdit) {
        formData.append("id", idinmueble);
        propUpdated = await APIpropiedadesPrivadas.update(formData);
      } else {
        propUpdated = await APIpropiedadesPrivadas.create(formData);
      }
      history(`/inmueble/${propUpdated?.id}`);
    } catch (error) {
      actions.setSubmitting(false);
      console.error("Error saving property:", error);
    }
  };

  return { propertyData, isLoadingPropiedad, isEdit, handleSaveProperty };
};
