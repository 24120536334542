import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import './InputModalVisita.css';
import { Button, Card, CardBody, Col, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row, Tooltip } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from "yup";
import Flatpickr from "react-flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es"
import { InputSelect } from '../InputSelect/InputSelect';
import { date_r, extractInitials, paginationModel, str_dt, tipoEventos } from '../../../../helpers/api_helper';
import { InputText } from '../InputText/InputText';
import { InputTextarea } from '../inputTextarea/inputTextarea';
import { CALENDAR, CLIENTES, PROPIEDADES, USERS } from '../../../../helpers/api_urls';
import { createApiMethods } from '../../../../helpers/api_calls';
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
// import { SelectDropdown } from '../SelectDropdown/SelectDropdown';
import DeleteModal from '../../../../Components/Common/DeleteModal';
import { useAuth } from '../../../../AuthContext';
import { estatusCalendarChoices } from '../../../../helpers/options';
import Can, { AbilityContext } from '../../../../helpers/acl/Can';
import { SelectDropdownCustom } from '../SelectDropdownCustom/SelectDropdownCustom';
import { VISITAAGENDADA } from '../../../../helpers/entities';
import { InputModal } from '../InputModal/InputModal';
  

const InputModalVisita = forwardRef(({reloader,...props},ref) => {
    const ability = useContext(AbilityContext)
    const [modal, setModal] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [event, setEvent] = useState({});
    const [selectedNewDay, setSelectedNewDay] = useState(0);
    const apiPropiedades = useMemo(() => createApiMethods(PROPIEDADES), []);
    const apiUsers = useMemo(() => createApiMethods(USERS), []);
    const apiClientes = useMemo(() => createApiMethods(CLIENTES), []);
    const [propiedadesList, setpropiedadesList] = useState(paginationModel);
    const [clientesList, setclientesList] = useState(paginationModel);
    const [showVisitas, setshowVisitas] = useState(false);
    const apiCalendar = useMemo(() => createApiMethods(CALENDAR), []);
    const [showForm, setshowForm] = useState(false);
    const [deleteModal, setdeleteModal] = useState(false);
    const [usersFromFranquicia, setusersFromFranquicia] = useState([]);
    const { user } = useAuth()
    const [ttop, setttop] = useState(false);
    const externalUrl = useRef(null);


    const toggleLoading = useCallback(() => {
        setisLoading(!isLoading)
    }, [isLoading, setisLoading]);

    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
            setEvent(null)
            setshowForm(false)
        } else {
            setModal(true);
        }
    }, [modal]);
    
    useEffect(()=>{
        Promise.all([
            apiPropiedades.getAll({page_size:1000}),
            apiClientes.getAll({page_size:1000}),
            apiUsers.getAll({page_size:1000}),
        ]).then((res)=>{
            setpropiedadesList(res[0])
            setclientesList(res[1])
            setusersFromFranquicia(res[2].results)
        }).catch(toast.error).finally(()=>{
            setisLoading(false)
        })
    },[])


    useImperativeHandle(ref, () => ({
        toggle,
        setEvent,
        setIsEdit,
        toggleLoading,
        handleDateClick,
        handleEventClick,
        setshowForm
    }));

    const validation = useFormik({
      enableReinitialize: true,  
      initialValues: {
        titulo: (event && event.titulo) || "",
        tipo: (event && event?.tipo?.id) || "",
        start: (event && event.start) || "",
        end: (event && event.end) || "",
        propiedad_id: (event && event?.propiedad?.id) || "",
        asesor_id: (event && event?.asesor?.id) || user.id,
        cliente_id: (event && event?.cliente?.id) || "",
        estatus: (event && event?.estatus?.id) || 1,
        descripcion: (event && event.descripcion) || "",
        ubicacion: (event && event.ubicacion) || "",
        link: (event && event.link) || "",
      },
  
      // validationSchema: Yup.object({
      //   titulo: Yup.string().required("Please Enter Your Event Name"),
      //   tipo: Yup.string().required("Please Select Your Category"),
      // }),

      onSubmit: (values) => {
        var updatedDay = "";
        if (selectedNewDay) {
          updatedDay = new Date(selectedNewDay[1]);
          updatedDay.setDate(updatedDay.getDate() + 1);
        }
  
        if (isEdit) {
          const updateEvent = {
            id         : event.id,
            titulo     : values.titulo,
            tipo       : values.tipo,
            start      : selectedNewDay ? selectedNewDay[0]: event.start,
            end        : selectedNewDay ? updatedDay       : event.end,
            propiedad_id  : values.propiedad_id,
            link  : values.link,
            cliente_id    : values.cliente_id,
            descripcion: values.descripcion,
            ubicacion: values.ubicacion,
            estatus: values.estatus,
            asesor_id: values.asesor_id,
          };
          validation.resetForm();
          setisLoading(true)
          apiCalendar.update(updateEvent).then((res)=>{
            toast.success(res)
            reloader()
            validation.resetForm()
          }).catch(toast.error).finally(()=>{
            setisLoading(false)
          })
        } else {
          const newEvent = {
            titulo     : values["titulo"],
            start      : selectedNewDay ? selectedNewDay[0]: values["start"],
            end        : selectedNewDay ? updatedDay       : values["end"],
            tipo       : Number(values.tipo),
            propiedad_id  : values["propiedad_id"],
            cliente_id    : values["cliente_id"],
            ubicacion: values["ubicacion"],
            link: values["link"],
            descripcion: values["descripcion"],
            estatus: values["estatus"],
            asesor_id: values["asesor_id"],
          };

          setisLoading(true)
          for (const clave in newEvent) {
            if (newEvent[clave] === "") {
              delete newEvent[clave];
            }
          }
          apiCalendar.create(newEvent).then((res)=>{
            toast.success(res)
            reloader()
            validation.resetForm()
          }).catch(toast.error).finally(()=>{
            setisLoading(false)
          })
        }
        setSelectedNewDay(null);
        toggle();
      },
    });

    const STATUS_CHOICES = [
      { id: 1, nombre: 'Pendiente' },
      { id: 2, nombre: 'Concreatada' },
      { id: 3, nombre: 'Cancelada' }
    ];    

    const { tipo } = validation.values
    useEffect(()=>{
      
      if(tipo === "1"){
        setshowVisitas(true)
      } else {
        setshowVisitas(false)
      }

    },[tipo])
    const handleEventClick = (arg) => {
      const event = arg.event;
      const st_date = event.start;
      const ed_date = event.end;
      const fecha_texto =
        ed_date == null
          ? str_dt(st_date)
          : str_dt(st_date) + " to " + str_dt(ed_date);
      const hora_texto =
        ed_date == null
          ? date_r(st_date)
          : date_r(st_date) + " to " + date_r(ed_date);
      setEvent({
        id: Number(event.id),
        titulo: event.title,
        start: event.start,
        end: event.end,
        link: event.link,
        ubicacion: event._def.extendedProps.ubicacion,
        descripcion: event._def.extendedProps.descripcion,
        propiedad: event._def.extendedProps.propiedad,
        cliente: event._def.extendedProps.cliente,
        tipo: event._def.extendedProps.tipo,
        estatus: event._def.extendedProps.estatus,
        hora_texto,fecha_texto,
        obj_type:VISITAAGENDADA,
        asesor: event._def.extendedProps.asesor,
      });
      setIsEdit(true);
      toggle();
    };

    const handleDateClick = (arg) => {
      const date = arg["date"];
      const day = date.getDate();
      const month = date.getMonth();
      const year = date.getFullYear();
  
      const currectDate = new Date();
      const currentHour = currectDate.getHours();
      const currentMin = currectDate.getMinutes();
      const currentSec = currectDate.getSeconds();
      const modifiedDate = new Date(
        year,
        month,
        day,
        currentHour,
        currentMin,
        currentSec
      );
  
      const modifiedData = { ...arg, date: modifiedDate };
      setSelectedNewDay([date,date]);
      setshowForm(true)
      validation.setFieldValue('defaultDate',date)
      validation.setFieldValue('start',date)
    };

    const mapPropiedades = (prop,index) =>{
      return <>
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0 me-3">
            <div className="avatar-sm bg-light rounded p-1">
              <img
                    src={prop.first_image_url}
                    alt=""
                    className="img-fluid d-block"/>
            </div>
          </div>
          <div className="flex-grow-1">
            <h5 className="fs-14 mb-1">
              <Link
                to="#"
                className="text-dark">
                {" "}
                {prop.nombre}
              </Link>
            </h5>
            <p className="text-muted mb-0">
              {/* Category :{" "} */}
              <span className="fw-medium">
                {prop?.tipo_inmueble?.nombre} {" "}-{" "} {prop?.tipo_inmueble?.nombre} {" "}-{" "} {prop?.precio}
              </span>
            </p>
          </div>
        </div>
      </>
    }

    const handleEditButton = () =>{
      setshowForm(true)
      if(event?.tipo?.id === 1)
        setshowVisitas(true)
    }

    const mapClientes = (prop,index) =>{
      return <>
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0 me-3">
            <div className="avatar-sm rounded">
                <div className="avatar-title border bg-light text-primary rounded text-uppercase fs-16">
                  {extractInitials(prop.nombre)}
                </div>
            </div>
          </div>
          <div className="flex-grow-1">
            <h5 className="fs-14 mb-1">
              <Link
                to="#"
                className="text-dark">
                {" "}
                {prop.nombre}
              </Link>
            </h5>
            <p className="text-muted mb-0">
              <span className="fw-medium">
                {prop?.franquicia?.nombre}
              </span>
            </p>
          </div>
        </div>
      </>
    }

    const mapStatus = (prop,index) =>{
      return  <span key={index} className="custom-badge badge w-100 mb-1 rounded-pill border border-primary text-primary badge-soft-primary">
          { prop?.nombre }
      </span>
    }

    const statusValidation = useFormik({
      enableReinitialize: true,
      initialValues: {
        estatus: event?._def?.extendedProps?.estatus?.id,
      },
      onSubmit: (values) => {
          console.log(values)
      },
  })

  const statuschange = statusValidation.values.estatus
  useEffect(()=>{
    if(!statuschange)
      return
    
    const updateEvent = {
      id         : event.id,
      estatus: statuschange
    };
    // validation.resetForm();
    setisLoading(true)
    apiCalendar.update(updateEvent).then((res)=>{
      toast.success('El estatus fue actualizado')
      reloader()
    }).catch(toast.error).finally(()=>{
      setisLoading(false)
    })    
  },[statuschange])


    const mapAsesor = (prop,index) =>{
      return <>
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0 me-3">
            <div className="avatar-sm rounded">
                <div className="avatar-title border bg-light text-primary rounded text-uppercase fs-16">
                  {extractInitials(prop.full_name)}
                </div>
            </div>
          </div>
          <div className="flex-grow-1">
            <h5 className="fs-14 mb-1">
              <Link
                to="#"
                className="text-dark">
                {" "}
                {prop.full_name}
              </Link>
            </h5>
            <p className="text-muted mb-0">
              <span className="fw-medium">
                {prop?.franquicia?.nombre}
              </span>
            </p>
          </div>
        </div>
      </>
    }

    const eventResume = <div className="event-details">
        <div className="d-flex mb-2">
            <div className="flex-grow-1 d-flex align-items-center mt-2">
                <div className="flex-shrink-0 me-3">
                    <i className="ri-calendar-event-line text-muted fs-16"></i>
                </div>
                <div className="flex-grow-1">
                    <h6
                        className="d-block fw-semibold mb-0"
                        id="event-start-date-tag">
                        {event ? `${event.fecha_texto} a las ${event.hora_texto}` : ""}
                    </h6>
                </div>
            </div>
        </div>
        <div className="d-flex align-items-center mb-2">
            <div className="flex-shrink-0 me-3">
                <i className="ri-map-pin-line text-muted fs-16"></i>
            </div>
            <div className="flex-grow-1">
                <h6 className="d-block fw-semibold mb-0">
                    {" "}
                    <span id="event-propiedad_id-tag">
                        {event && event.ubicacion ? event.ubicacion : "Sin ubicacion"}
                    </span>
                </h6>
            </div>
        </div>
        <div className="d-flex mb-3">
            <div className="flex-shrink-0 me-3">
                <i className="ri-discuss-line text-muted fs-16"></i>
            </div>
            <div className="flex-grow-1">
                <p
                    className="d-block text-muted mb-0"
                    id="event-descripcion-tag">
                    {event && event.descripcion !== undefined ? event.descripcion : "N/A"}
                </p>
            </div>
        </div>
        <div className="d-flex mb-3">
            <div className="flex-shrink-0 me-3">
                <i className="ri-user-2-line text-muted fs-16"></i>
            </div>
            <div className="flex-grow-1">
                <p
                    className="d-block text-muted mb-0"
                    id="event-descripcion-tag">
                    {event && event.asesor !== undefined ? event.asesor.full_name : "N/A"}
                </p>
            </div>
        </div>
        <div className="d-flex mb-3">
            <div className="flex-shrink-0 me-3">
                <i className="ri-community-line text-muted fs-16"></i>
            </div>
            <div className="flex-grow-1">
                <p
                    className="d-block text-muted mb-0"
                    id="event-descripcion-tag">
                    {event && event.asesor !== undefined ? event.asesor.franquicia.nombre : "N/A"}
                </p>
            </div>
        </div>
        <div className="d-flex mb-3 w-100">
            <SelectDropdownCustom
                map={mapStatus}
                pointer={ability.can('update',event)}
                disabled={!ability.can('update',event)}
                searchTerm={'nombre'}
                name         = "estatus"
                label        = "Estatus"
                tag='span'
                preselection={event?.estatus}
                classNameSelected='custom-badge cursor-pointer badge w-100 mb-1 rounded-pill border border-primary text-primary'
                validation   = {statusValidation}
                optionsProps = {
                    {
                        value:'id',
                        label:'nombre',
                        items: STATUS_CHOICES || []
                    }}>
            </SelectDropdownCustom>         
        </div>
    </div>;

    const handleDeleteEvent = () => {
      setisLoading(true)
      apiCalendar.delete(event).then((res)=>{
        toast.success(res)
        reloader()
        validation.resetForm()
      }).catch(toast.error).finally(()=>{
        setisLoading(false)
        setdeleteModal(false)
      })
      toggle();
    };

    const renderActionButtons = () => {
      if (isEdit) {
        return (
          <Can I="read" this={event}>
            <div className="text-end">
              <div className='d-flex flex-row justify-content-between w-100'>
                <Can I="delete" this={event}>
                  <button
                    type="button"
                    className="btn btn-sm mr-3 btn-soft-danger"
                    onClick={() => setdeleteModal(true)}>
                    <i className="ri-close-line align-bottom"></i> Eliminar
                  </button>
                </Can>
                <Can I="update" this={event}>
                  <Link
                    to="#"
                    className="btn btn-sm btn-soft-primary"
                    onClick={handleEditButton}>
                    Editar
                  </Link>
                </Can>
              </div>
              {eventResume}
            </div>
          </Can>
        );
      } 

      return null
    
    };

    const toggleExternalUrl = () => {
      externalUrl.current.toggle()
    }

    const handlePegar = async () => {
      const pastedText = await navigator.clipboard.readText();
      validation.setFieldValue('link',pastedText)
    }

    return (
        <>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={handleDeleteEvent}
            onCloseClick={() => setdeleteModal(false)}/>
          <ToastContainer></ToastContainer>

          <InputModal
            nocontrol={false}
            title={'Agregar un link externo'}
            submitEvent={() => externalUrl.current.toggle()}
            ref={externalUrl}>
              <Row>
                <Col xs={8}>
                  <InputText
                      name         = "link"
                      label        = "Url externa"
                      validation   = {validation}>
                  </InputText>
                </Col>
                <Col xl={4} className='d-flex flex-column-reverse'>
                  <div className="btn-group mt-4 mt-md-0" role="group" aria-label="Basic example">
                      <Button
                          onClick={()=> handlePegar()}
                          className='btn btn-success btn-block mb-3 btn-label waves-effect right waves-light'>
                              <i className="ri-file-copy-line label-icon align-middle fs-16 ms-2"></i>
                              Pegar
                      </Button>
                  </div>
                </Col>              
              </Row>
          </InputModal>      

          <Modal
              isOpen={modal}
              toggle={toggle}
              centered
              size={showForm ? 'lg' : 'sm'}
              className="border-0"
              modalClassName='modal fade zoomIn'>
              <ModalHeader toggle={toggle} tag="h5" className="p-3 bg-soft-info modal-titulo">
                {!!isEdit ? "Editar evento" : "Agregar evento"}
              </ModalHeader>
              <ModalBody>
                { showForm ?
                  <Row className="event-form">
                    <Col xs={12}>
                      <InputSelect
                          name         = "tipo"
                          label        = "Tipo de evento"
                          validation   = {validation}
                          optionsProps = {
                              {
                                  value:'id',
                                  label:'nombre',
                                  items:tipoEventos || []
                              }}>
                      </InputSelect> 
                    </Col>

                    <Col xs={12}>
                      <InputText
                          name         = "titulo"
                          label        = "Titulo del evento"
                          validation   = {validation}>
                      </InputText>
                    </Col>

                    <Col xs={6}>
                      <div className="mb-3">
                        <Label>Fecha</Label>
                        <div
                          className={
                            "input-group"
                          }
                          >
                          <Flatpickr
                              className="form-control"
                              name="defaultDate"
                              placeholder="Selecciona fecha"
                              value={validation.values.start || ""}
                              options={{
                                  mode: "single",
                                  enableTime: false,
                                  dateFormat: "Y-m-d",
                                  locale: Spanish,
                              }}
                            onChange={(date) => {
                              setSelectedNewDay(date);
                            }}/>
                          <span className="input-group-text">
                            <i className="ri-calendar-event-line"></i>
                          </span>
                        </div>
                      </div>
                    </Col>

                    <Col xs={6}>
                      <div className="mb-3">
                        <Label>{"Hora"}</Label>
                        <div
                          className={
                            "input-group"
                          }
                          >
                          <span className="input-group-text">
                            <i className="ri-map-pin-time-line"></i>
                          </span>

                          <Flatpickr
                              className="form-control"
                              placeholder="Selecciona hora"
                              onChange={(date) => {
                                  setSelectedNewDay(date);
                              }}
                              value={validation.values.start || ""}
                              options={{
                                  enableTime: true,
                                  noCalendar: true,
                                  dateFormat: "H:i",
                                  locale: Spanish,
                                  time_24hr: false
                              }}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col xs={12}>
                      <InputText
                          name         = "ubicacion"
                          label        = "Ubicacion"
                          validation   = {validation}>
                      </InputText>
                    </Col>
                    
                    <Col xs={12}>
                      <InputTextarea
                          name         = "descripcion"
                          label        = "Descripcion"
                          validation   = {validation}>
                      </InputTextarea>
                    </Col>

                    {
                      showVisitas &&
                      <>
                        <Col lg={4} xs={12} className='d-flex p-1'>
                          {/* {
                            validation.values.link !== "" ?
                            <a 
                              target="_blank" 
                              rel='noreferrer' 
                              href={validation.values.link}  
                              className="btn btn-success">
                                <i className="mdi mdi-home me-1"></i>Ver publicacion externa
                            </a> :
                            <SelectDropdown
                                map={mapPropiedades}
                                searchTerm={'nombre'}
                                name         = "propiedad_id"
                                label        = "Propiedad de visita"
                                validation   = {validation}
                                optionsProps = {
                                    {
                                        value:'id',
                                        label:'nombre',
                                        items:propiedadesList.results || []
                                    }}>
                            </SelectDropdown> 
                          } */}




                          <Tooltip
                            placement="top"
                            isOpen={ttop}
                            target="TooltipTop"
                            toggle={() => {
                              setttop(!ttop);
                            }}
                          >
                            Agregar link externo
                          </Tooltip>                          
                          <Button 
                            onClick={() => toggleExternalUrl()}
                            id="TooltipTop"
                            color="light" 
                            className="btn-icon ml-1"> 
                            <i className="ri-link" /> 
                          </Button>

                        </Col>
                        <Col lg={4} xs={12} className='p-1'>
                          {/* <SelectDropdown
                              map={mapClientes}
                              name         = "cliente_id"
                              searchTerm={'nombre'}
                              label        = "Cliente"
                              validation   = {validation}
                              optionsProps = {
                                  {
                                      value:'id',
                                      label:'nombre',
                                      items:clientesList.results || []
                                  }}>
                          </SelectDropdown>  */}
                        </Col>    
                        <Col lg={4}  xs={12} className='p-1'>
                            {/* <SelectDropdown
                                map={mapAsesor}
                                searchTerm={'full_name'}
                                name         = "asesor_id"
                                label        = "Asesor"
                                validation   = {validation}
                                optionsProps = {
                                    {
                                        value:'id',
                                        label:'nombre',
                                        items:usersFromFranquicia || []
                                    }}>
                            </SelectDropdown>  */}
                        </Col>
                      </>                        
                    }
                  </Row> :
                  <Card>
                    <CardBody>
                      {
                        renderActionButtons()
                      }
                    </CardBody>
                    
                  </Card>
                }
              </ModalBody>     
              <div className="modal-footer">
                  <div className="hstack gap-2 justify-content-end">
                      <Button
                          disabled={isLoading}
                          type="button"
                          onClick={() => {
                              setModal(false);
                          }}
                          className="btn-light">
                              Cancelar
                      </Button>
                      <Button
                          disabled={isLoading}
                          onClick={() => validation.submitForm()} 
                          type="button" 
                          className="btn btn-success btn-load">
                              {isLoading ?                                
                                  <span className="d-flex align-items-center">
                                      <span className="spinner-border flex-shrink-0" role="status">
                                          <span className="visually-hidden">Cargando...</span>
                                      </span>
                                      <span className="flex-grow-1 ms-2">
                                          Cargando...
                                      </span>
                                  </span> :
                                  <span>
                                      Aceptar
                                  </span>                        
                              }
                      </Button>
                  </div>
              </div>
          </Modal>
        </>
    );
});


InputModalVisita.displayName = 'InputModalVisita';
export { InputModalVisita }